import cx from 'classnames'
import { isNil } from 'lodash'
import { ChangeEvent, useContext, useEffect, useState } from 'react'

import { API_BASE_PATH_PORTAL } from '../../../api/auth'
import { useBeamDispatch } from '../../../hooks'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { setUser } from '../../../redux/actions'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamDropdown } from '../../../stories/BeamDropdown'
import { BEAM_DROPDOWN_OPTION } from '../../../stories/BeamDropdown/BeamDropdown'
import {
  BeamMultiSelectDropdownEventTarget,
  BeamMultiSelectDropdownOnChangeHandler,
} from '../../../stories/BeamDropdown/BeamDropdown.types'
import { BeamModal } from '../../../stories/BeamModal'
import { BeamTextfield } from '../../../stories/BeamTextfield'
import { BeamInputChangeEvent } from '../../../stories/BeamTextfield/BeamTextfield'
import { axiosRequest } from '../../../utils/axiosRequest'
import { formatDate } from '../../../utils/helpers/formatDate'
import {
  OverviewNonprofitImpactObject,
  OverviewPartnerImpactResponse,
  TUser,
} from '../../../utils/types'
import { CustomError } from '../../../utils/types/CustomError'
import { createAutoBoost, updateAutoBoost } from './AutoBoostANonprofitModal.api'
import {
  CreateAutoBoostAPIResponse,
  UpdateAutoBoostRequestBody,
  UpdateAutoBoostResponse,
} from './AutoBoostANonprofitModal.types'
import { CampaignPreview } from './CampaignPreview'
import { ModalContext } from './CampaignsPage/CampaignPage'
import { CampaignPromoObject } from './CampaignsPage/promo.types'
import $$ from './nonprofit-page.module.css'
import { PromoModal } from './PromoModalsComponent/PromoModalsComponent'
import { UpgradeWidgetCTA } from './UpgradeWidgetCTA'

const MAX_CHARACTERS_CAMPAIGN_NAME = 23

function isValidHexColor(hex: string): boolean {
  return /^#([a-fA-F0-9]{3}|[a-fA-F0-9]{6})$/i.test(hex)
}

function formatDateTime(date: string | Date, time = '00:00') {
  return new Date(date).toISOString().slice(0, 11) + time
}

interface EditablePromoProps {
  id: number | undefined
  name: string
  promoText: string
  availableToWholeChain: boolean
  boostAllNonprofits: boolean
  chainId: number
  boostedStoreNonprofitIds: number[]
  startTime: string
  endTime: string
  multiplier: number
  colorPrimary: string
  foregroundColor: string
}

const getInitialBoostData = (
  promo: CampaignPromoObject | null | undefined,
  chainId: number
): EditablePromoProps => {
  return {
    id: promo?.id || undefined,
    name: promo?.name || '',
    promoText: promo?.promoText || '',
    availableToWholeChain: promo?.availableToWholeChain || false,
    boostAllNonprofits: promo?.boostAllNonprofits || false,
    chainId: chainId,
    boostedStoreNonprofitIds: promo?.boostedStoreNonprofitIds || [],
    startTime: promo?.startTime || '',
    endTime: promo?.endTime || '',
    multiplier: promo?.multiplier || 0,
    colorPrimary: promo?.colorPrimary || '',
    foregroundColor: promo?.foregroundColor || '',
  }
}

const initialStartDate = () => {
  const today = new Date()
  today.setHours(0, 0)

  return formatDateTime(today)
}

const initialEndDate = () => {
  const today = new Date()
  today.setHours(0, 0)

  return formatDateTime(today, '23:59')
}

const boostAmountDropdownOptions: BEAM_DROPDOWN_OPTION[] = [
  { label: '2x', value: '2' },
  { label: '3x', value: '3' },
  { label: '4x', value: '4' },
  { label: '5x', value: '5' },
  { label: '10x', value: '10' },
  { label: '15x', value: '15' },
]

function getActiveNonprofitDropdownOptions(
  nonprofits: OverviewNonprofitImpactObject[]
): BEAM_DROPDOWN_OPTION[] {
  const activeNonprofits: BEAM_DROPDOWN_OPTION[] = nonprofits
    .filter(nonprofit => nonprofit.active)
    .map(nonprofit => ({
      label: nonprofit.name,
      value: `${nonprofit.storeNonprofit}`,
    }))

  return activeNonprofits
}

function getTomorrowDate() {
  const today = new Date()
  today.setHours(23, 59)

  const isoDate = today.toISOString().slice(0, 19)
  return isoDate
}

function calculateMaxDate(startTime: string) {
  const startTimeObject = new Date(startTime)
  const endTimeObject = new Date(startTimeObject)
  endTimeObject.setDate(startTimeObject.getDate() + 31)

  const year = endTimeObject.getFullYear()
  const month = (endTimeObject.getMonth() + 1).toString().padStart(2, '0')
  const day = endTimeObject.getDate().toString().padStart(2, '0')
  const hours = endTimeObject.getHours().toString().padStart(2, '0')
  const minutes = endTimeObject.getMinutes().toString().padStart(2, '0')
  const seconds = endTimeObject.getSeconds().toString().padStart(2, '0')

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
}

export async function fetchNonprofitImpacts(
  partnerId: number,
  callback: (nonprofits: OverviewNonprofitImpactObject[]) => void
) {
  try {
    const res = await axiosRequest('get', `${API_BASE_PATH_PORTAL}/partners/impact/${partnerId}`)
    const impactData = res?.data as OverviewPartnerImpactResponse
    callback(impactData?.nonprofits)
  } catch (error: any) {
    console.error(error)
  }
}

function isPromoUpdate(promoData: any): promoData is UpdateAutoBoostRequestBody {
  return !isNil(promoData.id)
}

export const AutoBoostANonprofitModal = ({
  partnerId,
  user,
  openToast,
  onCloseHandler,
  setMessage,
  openNonprofitModalAction = () => {
    return
  },
  promo,
}: {
  partnerId: number
  user: TUser
  openToast: () => void
  onCloseHandler: () => void
  setMessage: (e: CustomError | null) => void
  openNonprofitModalAction?: () => void
  promo?: CampaignPromoObject | null
}) => {
  const { setModal } = useContext(ModalContext)
  const chainId = user.chainId
  const dispatch = useBeamDispatch()

  const featureFlags = useFeatureFlags()
  const canUseNewPromoUI = featureFlags['can-use-new-promo-ui']
  const [open, setOpen] = useState(false)
  const [autoBoostData, setAutoBoostData] = useState<EditablePromoProps>(() =>
    getInitialBoostData(promo, chainId)
  )
  const [nonprofits, setNonprofits] = useState<OverviewNonprofitImpactObject[]>([])

  // fetches nonprofit data. TODO: When multi-store promos are supported, nonprofits data should be passed from the nonprofits page response.
  useEffect(() => {
    if (!user.partnerId) return

    fetchNonprofitImpacts(user.partnerId, nonprofitsResponse => {
      setNonprofits(nonprofitsResponse)
    })
  }, [user.partnerId])

  useEffect(() => {
    setOpen(true)
  }, [])

  useEffect(() => {
    setAutoBoostData(getInitialBoostData(promo, chainId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUpdateGlobalUser = (
    createAutoBoostResponse: CreateAutoBoostAPIResponse | UpdateAutoBoostResponse
  ) => {
    if (!featureFlags['enhanced-features']) return

    const updatedUser: TUser = {
      ...user,
      enhancedFeatures: {
        permissions: {
          ...user.enhancedFeatures?.permissions,
          ...createAutoBoostResponse.enhancedFeatures.permissions,
        },
      },
    }
    dispatch(setUser(updatedUser))
  }

  const captureAutoBoostModalValue = (e: any) => {
    const target =
      typeof e.target?.value === 'string'
        ? (e.target as HTMLInputElement)
        : (e.target as BeamMultiSelectDropdownEventTarget)

    if (target?.name) {
      let inputValue = target.value

      if (target.name === 'name') {
        inputValue = inputValue.slice(0, 50)
      }

      const newAutoBoostData = {
        ...autoBoostData,
        [target.name]: inputValue,
      }

      if (
        target.name === 'startTime' &&
        (!autoBoostData.endTime ||
          new Date(autoBoostData.endTime) <= new Date(inputValue as string))
      ) {
        // update the endTime when endTime is less than startTime
        const newEndTime = new Date(inputValue as string)
        newEndTime.setDate(newEndTime.getDate() + 1)
        newAutoBoostData.endTime = formatDateTime(newEndTime)
      }

      setAutoBoostData(newAutoBoostData)
    }
  }

  const handleColorChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    let formattedVal = val
    if (val.length >= 1 && val[0] !== '#') {
      formattedVal = '#' + val
    }

    setAutoBoostData({
      ...autoBoostData,
      [e.target.name]: formattedVal,
    })
  }

  const submitAutoBoostData = async () => {
    try {
      // promo text is no longer used in new promo UI
      const promoText = canUseNewPromoUI
        ? undefined
        : generatePromoText(activeNonprofitDropdownOptions)

      if (isPromoUpdate(autoBoostData)) {
        const updateAutoBoostResponse = await updateAutoBoost({
          requestData: autoBoostData,
          partnerId,
          user,
          promoText,
        })
        handleUpdateGlobalUser(updateAutoBoostResponse)
      } else {
        const createAutoBoostResponse = await createAutoBoost({
          requestData: autoBoostData,
          partnerId,
          user,
          promoText,
        })
        handleUpdateGlobalUser(createAutoBoostResponse)
      }

      setAutoBoostData(getInitialBoostData(null, chainId))
      setOpen(false)
      setMessage(null)
      setModal(PromoModal.NoModal)
      openToast()
      onCloseHandler()
    } catch (error: any) {
      let customError: CustomError | null = null
      if (error?.message && error?.code) {
        customError = {
          code: error.code,
          detail: error.message,
        }
      } else {
        customError = {
          code: 'UnknownError',
          detail: 'An error occurred while processing your request. Please try again later.',
        }
      }
      setMessage(customError)
      openToast()
    }
  }

  const areAllInputsFilled = () => {
    const {
      boostedStoreNonprofitIds,
      endTime,
      multiplier,
      name,
      startTime,
      colorPrimary,
      foregroundColor,
    } = autoBoostData
    // color only available in new promo UI. skip validation on old form
    const isValidColorHex = isValidHexColor(colorPrimary) && isValidHexColor(foregroundColor)
    const isValidColor =
      !canUseNewPromoUI ||
      (isValidColorHex && colorPrimary?.length > 1 && foregroundColor?.length > 1)

    return (
      boostedStoreNonprofitIds.length > 0 &&
      multiplier !== 0 &&
      endTime.trim() !== '' &&
      name.trim() !== '' &&
      name.trim().length <= MAX_CHARACTERS_CAMPAIGN_NAME &&
      startTime.trim() !== '' &&
      isValidColor
    )
  }

  const generatePromoText = (nonprofitIdToNameMap: BEAM_DROPDOWN_OPTION[]) => {
    const { boostedStoreNonprofitIds, multiplier, endTime, name } = autoBoostData

    const areAllNonprofitsSelected = (
      boostedStoreNonprofitIds: number[],
      nonprofitIdToNameMap: BEAM_DROPDOWN_OPTION[]
    ): boolean => {
      return boostedStoreNonprofitIds.length === nonprofitIdToNameMap.length
    }

    const concatNonprofitsNames = (boostedStoreNonprofitIds: number[]): string => {
      const nonprofitNames = boostedStoreNonprofitIds
        .map(id => {
          const nonprofitObject = nonprofitIdToNameMap.find(item => item.value === String(id))
          return nonprofitObject ? nonprofitObject.label.trim() : null
        })
        .filter(nonprofitName => nonprofitName !== null)
        .join(', ')

      return nonprofitNames
    }

    const nonprofitsText =
      boostedStoreNonprofitIds.length > 0
        ? areAllNonprofitsSelected(boostedStoreNonprofitIds, nonprofitIdToNameMap)
          ? 'all donations'
          : `donations to ${concatNonprofitsNames(boostedStoreNonprofitIds)}`
        : 'donations to [Nonprofits]'

    const campaignNameText = name ? `${name}` : '[Campaign Name]'
    const multiplierText = multiplier ? `${multiplier}x` : '[multiplier]'
    const endTimeText = endTime ? `${formatDate(endTime, 'MM/dd')}` : '[end date]'

    return `For ${campaignNameText}, we're boosting ${nonprofitsText} by ${multiplierText} until ${endTimeText}`
  }

  useEffect(() => {
    setOpen(true)
  }, [])

  const activeNonprofitDropdownOptions: BEAM_DROPDOWN_OPTION[] =
    getActiveNonprofitDropdownOptions(nonprofits)

  return (
    <BeamModal
      label="Boost a Nonprofit"
      open={open}
      onCloseCallback={() => onCloseHandler()}
      body={
        <>
          <div className={$$.modalDescription}>
            Select existing active nonprofit(s) for an automated boost. If you need to swap or add a
            new nonprofit,{' '}
            <button
              className={$$.linkToBoostACause}
              onClick={() => {
                setOpen(false)
                openNonprofitModalAction()
              }}>
              request a new nonprofit.
            </button>
          </div>
          <BeamDropdown
            options={activeNonprofitDropdownOptions}
            label={`Active Nonprofit(s)`}
            placeholder="Select Nonprofit to Boost"
            multiple
            name={'boostedStoreNonprofitIds'}
            value={autoBoostData.boostedStoreNonprofitIds?.map(storeNonprofitId =>
              String(storeNonprofitId)
            )}
            onChange={captureAutoBoostModalValue as BeamMultiSelectDropdownOnChangeHandler}
            hoist={true}
            className={$$.modalDropDown}
          />
          <BeamDropdown
            options={boostAmountDropdownOptions}
            placeholder="Select a Boost Amount"
            label={`Boost Amount`}
            name={'multiplier'}
            value={String(autoBoostData.multiplier)}
            onChange={captureAutoBoostModalValue}
            hoist={true}
            className={$$.modalDropDown}
          />
          <div className={cx($$.modalTextBoxContainer, 'grid grid-cols-9')}>
            <div className={cx($$.inputLabel, 'col-span-9')}>Boost Time Frame</div>

            <div className={cx($$.modalSubtext, 'col-span-9')}>Boosts can last up to 31 days</div>

            <BeamTextfield
              type="datetime-local"
              placeholder="start Date"
              name={`startTime`}
              className={cx($$.dateRange, 'col-span-4')}
              onChange={captureAutoBoostModalValue}
              value={autoBoostData.startTime ? autoBoostData.startTime : initialStartDate()}
              includeTime={true}
              min={getTomorrowDate()}
            />
            <div className={cx($$.toLabel, 'flex items-center justify-center')}>to</div>
            <BeamTextfield
              type="datetime-local"
              placeholder="end Date"
              name={`endTime`}
              className={cx($$.dateRange, 'col-span-4')}
              onChange={captureAutoBoostModalValue}
              value={autoBoostData.endTime ? autoBoostData.endTime : initialEndDate()}
              includeTime={true}
              min={autoBoostData.startTime ? autoBoostData.startTime : getTomorrowDate()}
              max={calculateMaxDate(autoBoostData.startTime)}
              disabled={!autoBoostData.startTime}
            />
          </div>
          {canUseNewPromoUI ? (
            <PreviewNew
              handleColorChange={handleColorChange}
              campaignName={autoBoostData.name}
              campaignColor={autoBoostData.colorPrimary}
              foregroundColor={autoBoostData.foregroundColor}
              multiplier={autoBoostData.multiplier}
              captureAutoBoostModalValue={captureAutoBoostModalValue}
            />
          ) : (
            <PreviewOld
              promoText={generatePromoText(activeNonprofitDropdownOptions)}
              captureAutoBoostModalValue={captureAutoBoostModalValue}
              campaignName={autoBoostData.name}
            />
          )}
        </>
      }
      footer={
        <BeamButton
          label="Confirm Boost"
          role="submit"
          variant="basic"
          className={$$.button}
          slot={'footer'}
          disabled={!areAllInputsFilled()}
          onClick={async () => await submitAutoBoostData()}
        />
      }
    />
  )
}

const PreviewNew = ({
  campaignColor,
  foregroundColor,
  campaignName,
  multiplier,
  captureAutoBoostModalValue,
  handleColorChange,
}: {
  campaignColor: string | null
  foregroundColor: string | null
  campaignName: string
  multiplier: number
  captureAutoBoostModalValue: BeamInputChangeEvent
  handleColorChange: (e: ChangeEvent<HTMLInputElement>) => void
}) => {
  const campaignNameInputOverLimit = campaignName.length > MAX_CHARACTERS_CAMPAIGN_NAME
  const [colorPrimaryValid, setColorPrimaryValid] = useState(true)
  const [foregroundColorValid, setForegroundColorValid] = useState(true)
  return (
    <>
      <BeamTextfield
        name="name"
        label="Campaign Name"
        placeholder="e.g. “Breast Cancer Awareness”, “This Week Only!”"
        onChange={captureAutoBoostModalValue}
        value={campaignName}
        className={$$.modalTextBoxContainer}
      />
      <div
        className={cx($$.campaignNameCharacterCount, {
          [$$.overLimit]: campaignNameInputOverLimit,
        })}>
        {campaignName.length}/{MAX_CHARACTERS_CAMPAIGN_NAME} character maximum
      </div>
      <div className={cx($$.modalTextBoxContainer)}>
        <div className={$$.inputLabel}>Campaign Color</div>
        <div className={cx($$.modalSubtext, 'pt-2')}>
          Tip: Use eye catching WCAG accessible high contrast color
        </div>
        <BeamTextfield
          name="colorPrimary"
          onChange={e => {
            handleColorChange(e)
            setColorPrimaryValid(isValidHexColor(e.target.value))
          }}
          value={campaignColor ?? undefined}
          placeholder="Hex Code #"
          className="pt-2"
        />
        <span
          className={cx(
            'font-secondary text-sm absolute text-cherry-600',
            colorPrimaryValid ? 'invisible' : 'visible'
          )}>
          Invalid Hex color
        </span>
      </div>
      <div className={cx($$.modalTextBoxContainer)}>
        <div className={$$.inputLabel}>Campaign Text Color</div>
        <div className={cx($$.modalSubtext, 'pt-2')}>
          Tip: Use eye catching WCAG accessible high contrast color
        </div>
        <BeamTextfield
          name="foregroundColor"
          onChange={e => {
            handleColorChange(e)
            setForegroundColorValid(isValidHexColor(e.target.value))
          }}
          value={foregroundColor ?? undefined}
          placeholder="Hex Code #"
          className="pt-2 relative"
        />
        <span
          className={cx(
            'font-secondary text-sm  text-cherry-600 absolute',
            foregroundColorValid ? 'invisible' : 'visible'
          )}>
          Invalid Hex color
        </span>
      </div>
      <div className={cx($$.inputLabel, $$.modalTextBoxContainer)}>Campaign Preview</div>
      <CampaignPreview
        colorPrimary={campaignColor ?? undefined}
        foregroundColor={foregroundColor ?? undefined}
        multiplier={multiplier}
        campaignName={campaignName}
      />
      <div className={$$.modalDescription}>
        Note: All nonprofits & icons are placeholder; your actual nonprofit details will display on
        site
      </div>
    </>
  )
}

const PreviewOld = ({
  promoText,
  campaignName,
  captureAutoBoostModalValue,
}: {
  promoText: string
  campaignName: string
  captureAutoBoostModalValue: BeamInputChangeEvent
}) => {
  return (
    <>
      <BeamTextfield
        name="name"
        label="Campaign Name"
        placeholder="ie. Breast Cancer Awareness Month"
        onChange={captureAutoBoostModalValue}
        value={campaignName}
        className={$$.modalTextBoxContainer}
      />
      <div
        className={cx($$.campaignNameCharacterCount, {
          [$$.overLimit]: campaignName.length > MAX_CHARACTERS_CAMPAIGN_NAME,
        })}>
        {campaignName.length}/{MAX_CHARACTERS_CAMPAIGN_NAME} character maximum
      </div>
      <div className={$$.modalPromoPreview}>
        <b className={$$.promoTextTitle}>
          Copy that will appear in the Beam integration at checkout:
        </b>
        <div className={$$.promoText}>{promoText}</div>
        <UpgradeWidgetCTA />
      </div>
    </>
  )
}
