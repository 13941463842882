import cx from 'classnames'
import { get } from 'lodash'
import { Link } from 'react-router-dom'

import { BeamDataPointBlock } from '../../../stories/BeamDataPointBlock'
import { numberFormat } from '../../../utils/helpers/stringFormatter'
import { percentFormat } from '../../../utils/root'
import { TitleWithTooltip } from '../../root/TitleWithTooltip'
import { ReactComponent as GraphIcon } from './assets/graphIcon.svg'
import { ReactComponent as PeopleIcon } from './assets/peopleIcon.svg'
import $$ from './overview-page.module.css'

interface MonthlyCustomerEngagementSectionProps {
  calculatedData: Record<any, any> | null
  loading?: boolean
}

export const MonthlyCustomerEngagementSection = ({
  calculatedData,
  loading = true,
}: MonthlyCustomerEngagementSectionProps) => {
  return (
    <>
      <div className="grid grid-cols-1 mt-4 desktop:grid-cols-2">
        <div className="my-auto">
          <h2 className="beam--heading--2">Customer Engagement This Month</h2>
        </div>
      </div>

      <div className="grid grid-cols-1 space-x-0 space-y-4 desktop:space-x-4 desktop:space-y-0 desktop:grid-cols-2">
        <div>
          <BeamDataPointBlock
            title={
              <TitleWithTooltip
                title={'Redemption Rate'}
                tooltipContent={
                  'The % of orders through your core checkout flow that have a donation redeemed associated (i.e., excl. donations redeemed among any recurring subscribers, if applicable)'
                }
              />
            }
            datum={
              get(calculatedData, 'redemptionRate', 0) > 0
                ? percentFormat(calculatedData?.redemptionRate)
                : '--'
            }
            icon={<GraphIcon />}
            description={
              get(calculatedData, 'redemptionRate', 0) > 0 ? null : 'Not enough data collected yet'
            }
            descriptionStyle={'no-data'}
            footer={
              get(calculatedData, 'redemptionRate', 0) > 0 ? (
                <Link to={`/reports`}>View Report</Link>
              ) : null
            }
            backgroundColor={'--beam-color--sky-50'}
            className={cx('h-full', $$.customerEngagementCard)}
            loading={loading}
          />
        </div>
        <div>
          <BeamDataPointBlock
            title={
              <TitleWithTooltip
                title={'Customers Using Beam'}
                tooltipContent={
                  'The number of customers who have placed an order through your core checkout flow with an associated donation redeemed (i.e. excludes recurring subscribers if applicable)'
                }
              />
            }
            datum={
              get(calculatedData, 'customersUsingBeam', 0) > 0
                ? numberFormat(+calculatedData?.customersUsingBeam)
                : '--'
            }
            description={
              get(calculatedData, 'customersUsingBeam', 0) > 0
                ? null
                : 'Not enough data collected yet'
            }
            descriptionStyle={'no-data'}
            icon={<PeopleIcon />}
            backgroundColor={'--beam-color--sky-50'}
            className={cx('h-full', $$.customerEngagementCard)}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}
