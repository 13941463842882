export type TStateWithLoading<T = Record<any, any>> = {
  data: T | null
  loading: boolean
  error: string | null
}

export enum ROIModuleState {
  Show = 'Show', // Display the ROI Module
  Hidden = 'Hidden', // Hide the ROI Module
  SiteSpecific = 'SiteSpecific', // Display disclaimer mentioning we don't have data
}

export interface OptimalRoiMetricsData {
  averageOrderFrequencyLift: number | null
  averageOrderValueLift: number | null
  beamAOV: number | null
  beamAOVSalesLift: number | null
  beamCCR: number | null
  beamFees: number | null
  beamOrderFrequency: number | null
  beamOrderFrequencySalesLift: number | null
  beamROI: number | null
  blendedROI: number | null
  ccrLift: number | null
  ccrSalesLift: number | null
  customersUsingBeam: number | null
  donations: number | null
  isEstimatedCCRIncluded: boolean
  nonBeamCCR: number | null
  nonBeamOrderFrequency: number | null
  nonbeamAOV: number | null
  pctCcrLift: number | null
  redemptionRate: number | null
  reportPeriodId: string | null
  roiPeriodDate: string | null
  salesLift: number | null
  roiModuleState: ROIModuleState
  totalCCR: number | null
  totalFees: number | null
}

export interface SupplementaryRoiMetrics {
  beamTransactions: number | null
  customersUsingBeam: number | null
  redemptionRate: number | null
  redemptionsInLastSevenDays: number | null
  ccrDiscountFactor: number | null
}

export type RoiMetricsResponsePayload = {
  optimalMetrics: OptimalRoiMetricsData
  supplementaryMetrics: SupplementaryRoiMetrics
  storeId?: number
}

export type BusinessImpactStateWithLoading = TStateWithLoading<RoiMetricsResponsePayload>

export interface NonprofitType {
  name: string
  cause: string
  cause_icon: string
  image: string | null
  website: string
  impact_description: string
  impact: Impact
  target_image: string | null
  thin_target_image: string | null
  chain_target_image: string | null
  regions: string[]
  id: number
  active: boolean
  isTrending?: boolean | null
  storeNonprofit?: number
}

export interface Impact {
  chain_donated: number
  match_donated: number
  total_donated: number
  target_donation_amount: number
}

// ==================== ROI with Subscriptions ====================

export interface DataWikiObject {
  beamFirstSubscriptionCcr: number | null
  abTestDiscountFactor: number | null
  ccrDiscountFactor: number | null
  estimatedGlobalAbTestDiscountFactor: number
  incrementalFirstSubscriptionsAttributedToBeam: number | null
  incrementalLtv: number | null
  incrementalSubscriberRevenue: number | null
  nonBeamFirstSubscriptionCcr: number | null
  sixMonthNonSubscriptionLtv: number | null
  sixMonthSubscriptionLtv: number | null
}

export interface RoiMetricsObject {
  averageOrderFrequencyLift: number | null
  averageOrderValueLift: number | null
  baselineFirstSubscriptions: number | null
  beamAOV: number | null
  beamAOVSalesLift: number | null
  beamCCR: number | null
  beamFees: number | null
  beamFirstSubscriptionCcr: number | null
  beamNonSubscriptionAOV: number | null
  beamNonSubscriptionAovSalesLift: number | null
  beamNonSubscriptionOrderFrequency: number | null
  beamNonSubscriptionOrderFrequencySalesLift: number | null
  beamOrderFrequency: number | null
  beamOrderFrequencySalesLift: number | null
  beamROI: number | null
  beamSubscriptionAov: number | null
  beamSubscriptionAovSalesLift: number | null
  beamSubscriptionFirstOrders: number | null
  beamSubscriptionOrderFrequency: number | null
  beamSubscriptionOrderFrequencySalesLift: number | null
  blendedROI: number | null
  ccrLift: number | null
  ccrSalesLift: number | null
  customersUsingBeam: number | null
  donations: number | null
  incrementalFirstSubscriptionsAttributedToBeam: number | null
  isEstimatedCCRIncluded: boolean
  nonBeamCCR: number | null
  nonBeamFirstSubscriptionCcr: number | null
  nonBeamNonSubscriptionAOV: number | null
  nonBeamNonSubscriptionOrderFrequency: number | null
  nonBeamOrderFrequency: number | null
  nonBeamSubscriptionAov: number | null
  nonBeamSubscriptionFirstOrders: number | null
  nonBeamSubscriptionOrderFrequency: number | null
  nonSubscriptionAverageOrderFrequencyLift: number | null
  nonSubscriptionAverageOrderValueLift: number | null
  nonbeamAOV: number | null
  pctCcrLift: number | null
  redemptionRate: number | null
  reportPeriodId: string | null
  roiModuleState: 'Show' | 'Hidden' | 'SiteSpecific'
  roiPeriodDate: string | null
  salesLift: number | null
  shouldDisplayRoiModule: boolean
  subscriberSignUpSalesLift: number | null
  subscriptionAverageOrderFrequencyLift: number | null
  subscriptionAverageOrderValueLift: number | null
  subscriptionSignUpLift: number | null
  totalCCR: number | null
  totalCarts: number | null
  totalFees: number | null
  totalSubscriptionFirstOrders: number | null
  totalTransactions: number | null
}

export type OptimalRoiWithSubscriptionsResponseWithLoading =
  TStateWithLoading<OptimalRoiWithSubscriptionsResponse>

export interface OptimalRoiWithSubscriptionsResponse {
  dataWiki: DataWikiObject
  sixMonthViewMetrics: RoiMetricsObject
  oneMonthMetrics: {
    optimalMetrics: RoiMetricsObject
    supplementaryMetrics: SupplementaryRoiMetrics
  }
  storeId?: number | null
}
