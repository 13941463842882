import cx from 'classnames'
import { useState } from 'react'

import { useBeamSelector } from '../../../hooks'
import { BeamDataPointBlock } from '../../../stories/BeamDataPointBlock'
import { BeamLoadingIndicator } from '../../../stories/BeamLoadingIndicator'
import { BeamTooltip } from '../../../stories/BeamTooltip'
import { isPositiveNumber } from '../../../utils/helpers/isPositiveNumber'
import { dollarFormat, percentFormat } from '../../../utils/root'
import { TUser } from '../../../utils/types'
import { CenteredContent } from '../../root/CenteredContent'
import { TitleWithTooltip } from '../../root/TitleWithTooltip'
import { ReactComponent as CartIcon } from './assets/cartIcon.svg'
import { ReactComponent as GraphIcon } from './assets/graphIcon.svg'
import { ReactComponent as InfoIcon } from './assets/infoIcon.svg'
import { ReactComponent as UpwardTrendIcon } from './assets/upwardTrendIcon.svg'
import $$ from './overview-page.module.css'
import { overviewTestIds } from './OverviewPage.helper'
import { OptimalRoiMetricsData } from './OverviewPage.types'

function shouldShowCCRMetrics(calculatedData: OptimalRoiMetricsData): boolean {
  // always show ccr metrics for brands using estimated CCR
  if (calculatedData.isEstimatedCCRIncluded && isPositiveNumber(calculatedData.pctCcrLift))
    return true

  return (
    isPositiveNumber(calculatedData.beamCCR) &&
    isPositiveNumber(calculatedData.pctCcrLift) &&
    !!calculatedData.reportPeriodId
  )
}

function shouldWeDisplayAOV(calculatedData: OptimalRoiMetricsData): boolean {
  return (
    isPositiveNumber(calculatedData.averageOrderValueLift) &&
    isPositiveNumber(calculatedData.beamAOVSalesLift)
  )
}

function shouldWeDisplayAOF(calculatedData: OptimalRoiMetricsData): boolean {
  return (
    isPositiveNumber(calculatedData.averageOrderFrequencyLift) &&
    isPositiveNumber(calculatedData.beamOrderFrequencySalesLift)
  )
}

function howManyMetricsAreVisible(calculatedData: OptimalRoiMetricsData): number {
  return [
    shouldWeDisplayAOF(calculatedData),
    shouldWeDisplayAOV(calculatedData),
    shouldShowCCRMetrics(calculatedData),
  ].filter(entry => entry).length
}

function displayDateRange(calculatedData: OptimalRoiMetricsData | null): string {
  // remove "M123" or "Q123" prefixes
  return calculatedData?.roiPeriodDate?.replace(/[Q|M][0-9]./, '').trim() || 'N/A'
}

interface BusinessImpactModuleProps {
  calculatedData: OptimalRoiMetricsData | null
  ccrDiscountFactor: number | null
  loading?: boolean
}

export const BusinessImpactModule = ({
  calculatedData,
  ccrDiscountFactor,
  loading = true,
}: BusinessImpactModuleProps) => {
  const user = useBeamSelector(({ user }) => user) as TUser
  const partnerName = user?.partner.name
  const [displayExpandedData, setDisplayExpandedData] = useState<boolean>(false)
  const hasBrandSpecificCcrDiscountFactor = !!ccrDiscountFactor

  function renderMainContent() {
    // This section should not show if there's no data for business impact.
    if (!calculatedData) return <></>

    const pctCcrDisplayValue = percentFormat(calculatedData.pctCcrLift, null, 1, true)

    return (
      <>
        <div
          className={cx('grid grid-cols-1 desktop:grid-cols-3', $$.businessImpactPart1)}
          data-testid={overviewTestIds.blendedRoiMetric}>
          <div data-testid={overviewTestIds.salesLiftRoiMetric}>
            <BeamDataPointBlock
              title={
                <TitleWithTooltip
                  title={
                    'Sales Lift' + (calculatedData.isEstimatedCCRIncluded ? ' (with est. CCR)' : '')
                  }
                  tooltipContent={
                    calculatedData.isEstimatedCCRIncluded ? (
                      <>
                        The conservative total incremental revenue driven by the Beam integration by
                        optimizing your on-site experience, beyond the revenue you would have
                        generated anyway. Sales Lift here is calculated using minimum CCR Lift for
                        brands on Beam (+1%).
                        <br />
                        <br />
                        Note: Min. monthly incremental revenue is conservative, as it excludes the
                        revenue impact of top-of-funnel activations (e.g., increased ad efficiency,
                        friend referral, social sharing, and new customers driven from Beam’s
                        consumer app)
                      </>
                    ) : (
                      <>
                        The conservative total incremental revenue driven by the Beam integration by
                        optimizing your on-site experience, beyond the revenue you would have
                        generated anyway.
                        <br />
                        <br />
                        Note: Min. monthly incremental revenue is conservative, as it excludes the
                        revenue impact of top-of-funnel activations (e.g., increased ad efficiency,
                        friend referral, social sharing, and new customers driven from Beam’s
                        consumer app).
                      </>
                    )
                  }
                />
              }
              datum={calculatedData.salesLift ? `~${dollarFormat(calculatedData.salesLift)}` : '--'}
              datumSize="large"
            />
          </div>

          <div>
            <BeamDataPointBlock
              title={
                <TitleWithTooltip
                  title="ROI against Donations and Beam Fees"
                  tooltipContent={
                    <>
                      Top-line sales impact / (Beam fees + total donations)
                      <br />
                      <br />
                      Note: if your brand was already donating prior to launching the Beam
                      integration, see &quot;ROI against Beam fees&quot; for ROI on just incremental
                      program costs
                    </>
                  }
                />
              }
              datum={`~${calculatedData.blendedROI?.toFixed(1)}x`}
              datumSize="large"
            />
          </div>

          <div data-testid={overviewTestIds.beamRoiMetric}>
            <BeamDataPointBlock
              title={
                <TitleWithTooltip
                  title="ROI against Beam Fees only"
                  tooltipContent={
                    <>
                      Top-line sales lift / Beam fees
                      <br />
                      <br />
                      Note: this is the accurate representation of program ROI for brands that were
                      already donating prior to launch Beam
                    </>
                  }
                />
              }
              datum={calculatedData.beamROI ? `~${calculatedData.beamROI.toFixed(1)}x` : '--'}
              datumSize="large"
            />
          </div>

          <div className="desktop:col-span-3" data-testid={overviewTestIds.roiDetailsView}>
            <button
              className={cx($$.expansionButton, 'text-right w-full underline p-5 leading-5')}
              onClick={() => setDisplayExpandedData(!displayExpandedData)}>
              {displayExpandedData ? 'Collapse' : 'See'} ROI Calculation
            </button>
            <div className={cx(displayExpandedData ? 'block' : 'hidden', $$.expandedStats, 'p-4')}>
              {[
                shouldWeDisplayAOV(calculatedData),
                shouldWeDisplayAOF(calculatedData),
                shouldShowCCRMetrics(calculatedData),
              ].some(checkIfAnyIsTrue => checkIfAnyIsTrue) && (
                <>
                  <div className="grid grid-cols-2 font-bold">
                    <h3>Estimated monthly sales lift driven</h3>
                    <h3 className="text-right">{`~${dollarFormat(calculatedData.salesLift)}`}</h3>
                  </div>
                  {shouldWeDisplayAOV(calculatedData) && (
                    <div className="grid grid-cols-2 text-charcoal-500">
                      <div>Incremental lift from increased Average Order Value</div>
                      <div className="text-right">
                        {dollarFormat(calculatedData.beamAOVSalesLift)}
                      </div>
                    </div>
                  )}
                  {shouldWeDisplayAOF(calculatedData) && (
                    <div className="grid grid-cols-2 text-charcoal-500">
                      <div>Incremental lift from increased Average Order Frequency</div>
                      <div className="text-right">
                        {dollarFormat(calculatedData.beamOrderFrequencySalesLift)}
                      </div>
                    </div>
                  )}
                  {shouldShowCCRMetrics(calculatedData) && (
                    <div className="grid grid-cols-2 text-charcoal-500">
                      {calculatedData.isEstimatedCCRIncluded ? (
                        <div>
                          <em>
                            Estimated Incremental lift from increased Cart Completion Rate (+
                            {pctCcrDisplayValue})
                          </em>
                        </div>
                      ) : (
                        <div>Incremental lift from increased Cart Completion Rate</div>
                      )}
                      <div className="text-right">
                        {calculatedData.isEstimatedCCRIncluded ? (
                          <em>{dollarFormat(calculatedData.ccrSalesLift)}</em>
                        ) : (
                          dollarFormat(calculatedData.ccrSalesLift)
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className="grid grid-cols-2 font-bold">
                <h3>Total monthly costs</h3>
                <h3 className="text-right">{dollarFormat(calculatedData.totalFees ?? 0)}</h3>
              </div>
              <div className="grid grid-cols-2 text-charcoal-500">
                <div>
                  Donations{' '}
                  {!user.partner.inKindDonations.displayInKindDonations
                    ? ''
                    : '(shipping cost of in-kind donations)'}
                </div>
                <div className="text-right">{dollarFormat(calculatedData.donations ?? 0)}</div>
                <div>Beam Fees</div>
                <div className="text-right">{dollarFormat(calculatedData.beamFees ?? 0)}</div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={cx(
            `grid grid-cols-1 space-x-0 space-y-4 desktop:grid-cols-${
              howManyMetricsAreVisible(calculatedData) <= 2 ? 2 : 3
            } desktop:space-y-0 desktop:space-x-4`,
            $$.businessImpactPart2
          )}>
          {shouldWeDisplayAOV(calculatedData) && (
            <div className={`${$$.block}`}>
              <BeamDataPointBlock
                title={
                  <TitleWithTooltip
                    title="Average Order Value Lift"
                    tooltipContent={`The % increase in AOV driven by Beam, calculated by taking ([AOV among transactions with a Beam redemption] - [AOV among transactions without Beam usage])/[AOV among transactions without Beam usage]. This methodology is consistent with what our partners see from A/B tests with Beam as well`}
                  />
                }
                datum={`+${percentFormat(calculatedData.averageOrderValueLift, null, 1)}`}
                description={
                  <span className={`iconOverrideOverviewPage ${$$.roiMetricComparisonSubheading}`}>
                    <UpwardTrendIcon /> ({dollarFormat(calculatedData.nonbeamAOV, 2)} without Beam
                    vs {dollarFormat(calculatedData.beamAOV, 2)} with Beam)
                  </span>
                }
                icon={<GraphIcon />}
                backgroundColor={'--beam-color--lime-100'}
              />
            </div>
          )}

          {shouldWeDisplayAOF(calculatedData) && (
            <div className={`${$$.block}`}>
              <BeamDataPointBlock
                title={
                  <TitleWithTooltip
                    title="Average Order Frequency Lift"
                    tooltipContent={`The difference between the average number of transactions per unique customer where a donation was not redeemed and the average number of transactions per unique customer where a donation was redeemed`}
                  />
                }
                datum={`+${percentFormat(calculatedData.averageOrderFrequencyLift, null, 1)}`}
                description={
                  <span className={`iconOverrideOverviewPage ${$$.roiMetricComparisonSubheading}`}>
                    <UpwardTrendIcon />({calculatedData.nonBeamOrderFrequency?.toFixed(3) || 'N/A'}{' '}
                    without Beam vs {calculatedData.beamOrderFrequency?.toFixed(3) || 'N/A'} With
                    Beam)
                  </span>
                }
                icon={<GraphIcon />}
                backgroundColor={'--beam-color--lime-100'}
              />
            </div>
          )}

          {shouldShowCCRMetrics(calculatedData) && (
            <div className={$$.block} data-testid={overviewTestIds.roiCcrLiftBlock}>
              <BeamDataPointBlock
                title={
                  <TitleWithTooltip
                    title={
                      calculatedData.isEstimatedCCRIncluded
                        ? 'Est. Cart Completion Rate (CCR) Lift'
                        : 'Cart Completion Rate (CCR) Lift'
                    }
                    tooltipContent={
                      hasBrandSpecificCcrDiscountFactor ? (
                        <>
                          Cart completion rate (CCR) is the inverse of cart abandonment rate: the %
                          of unique customers who reach cart that convert (total conversions / total
                          unique carts).
                          <br />
                          <br />
                          To measure the impact that Beam has on CCR, we first compare the CCR of
                          one-time customers checking out with Beam against those checking out
                          without using Beam. Then, to account for the fact that customers using
                          Beam are inherently more engaged, we then multiply this by a {
                            partnerName
                          }{' '}
                          specific A/B test discount factor to account for any selective bias:{' '}
                          {percentFormat(ccrDiscountFactor)}
                        </>
                      ) : (
                        <>
                          Cart completion rate (CCR) is the inverse of cart abandonment rate: the %
                          of unique customers who reach cart that convert (total conversions / total
                          unique carts).
                          <br />
                          <br />
                          To measure the impact that Beam has on CCR, we first compare the CCR of
                          one-time customers checking out with Beam against those checking out
                          without using Beam. Then, to account for the fact that customers using
                          Beam are inherently more engaged, to be conservative we discount the
                          result by a minimum of 91%, applying learnings from our 100+ A/B tests to
                          remove selection bias.
                        </>
                      )
                    }
                  />
                }
                datum={
                  <div className="flex items-center">
                    <span className={'pr-2'}>
                      {calculatedData.isEstimatedCCRIncluded ? (
                        <em>{`~${pctCcrDisplayValue}`}</em>
                      ) : (
                        `+${pctCcrDisplayValue}`
                      )}
                    </span>
                    {calculatedData.isEstimatedCCRIncluded && (
                      <BeamTooltip
                        content={`Since Beam doesn't currently have access to ${user.partner.name}'s cart data, we've provided a very conservative view of sales lift assuming we only drive a ${pctCcrDisplayValue} improvement in ${user.partner.name}'s cart completion rate, a figure we've consistently exceeded in A/B tests`}>
                        <div className={'flex items-center'}>
                          <InfoIcon className="inline" />
                        </div>
                      </BeamTooltip>
                    )}
                  </div>
                }
                description={
                  <span className={`iconOverrideOverviewPage ${$$.roiMetricComparisonSubheading}`}>
                    <UpwardTrendIcon />({percentFormat(calculatedData.beamCCR, null, 0)} Beam vs{' '}
                    {percentFormat(calculatedData.nonBeamCCR, null, 0)} Non-Beam)
                  </span>
                }
                icon={<CartIcon />}
                backgroundColor={'--beam-color--lime-100'}
              />
            </div>
          )}
        </div>
      </>
    )
  }

  const roiDateRangePeriod = displayDateRange(calculatedData)

  return (
    <div className={cx($$.businessImpactSection, { 'h-[800px] desktop:h-[450px]': loading })}>
      <div className="grid grid-cols-1">
        <div className="my-auto">
          <h2 className="mb-2">Recent ROI Highlights</h2>
        </div>

        <p className={cx($$.businessImpactDateRange, 'mb-[24px]')}>
          ROI calculation from {roiDateRangePeriod}. These estimates are conservative and represent
          the minimum potential ROI.
        </p>
      </div>

      {loading && (
        <CenteredContent>
          <BeamLoadingIndicator />
        </CenteredContent>
      )}

      {!loading && calculatedData && renderMainContent()}

      {!loading && !calculatedData && (
        <CenteredContent>
          <p className="font-bold">There was a problem fetching this data.</p>
        </CenteredContent>
      )}
    </div>
  )
}
