import { useContext, useState } from 'react'

import { getV2ApiUrl } from '../../../../api/auth'
import { useBeamDispatch, useBeamSelector } from '../../../../hooks'
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags'
import { setUser } from '../../../../redux/actions'
import { BeamButton } from '../../../../stories/BeamButton'
import { BeamModal } from '../../../../stories/BeamModal'
import { BeamToast } from '../../../../stories/BeamToast'
import { axiosRequest } from '../../../../utils/axiosRequest'
import { TUser } from '../../../../utils/types'
import { CustomError } from '../../../../utils/types/CustomError'
import { AutoBoostANonprofitModal } from '../AutoBoostANonprofitModal'
import { ModalContext } from '../CampaignsPage/CampaignPage'
import { CampaignPromoObject } from '../CampaignsPage/promo.types'
import { customErrorMessageHelper } from '../NonprofitPartnersPage'
import { RequestNewNonprofitCauseModal } from '../RequestNewNonprofitCauseModal'

export enum PromoModal {
  NoModal = 'NoModal',
  BoostANonprofit = 'BoostANonprofit',
  RequestANewNonprofit = 'RequestANewNonprofit',
  DeleteAPromoConfirmation = 'DeleteAPromoConfirmation',
}

export const PromoModalsComponent = ({
  activeModal,
  currentPromo,
}: {
  activeModal: PromoModal
  currentPromo: CampaignPromoObject | null
}) => {
  const { setModal, setCurrentPromo } = useContext(ModalContext)
  const user: TUser | undefined = useBeamSelector(({ user }) => user)
  const [openToast, setOpenToast] = useState<boolean>(false)
  const [customErrorMessage, setCustomErrorMessage] = useState<CustomError | null>(null)
  const [customSuccessMessage, setCustomSuccessMessage] = useState<string>('')
  const dispatch = useBeamDispatch()
  const featureFlags = useFeatureFlags()

  const layout: React.ReactNode[] = []

  if (!user) return <></>

  switch (activeModal) {
    case PromoModal.BoostANonprofit:
      layout.push(
        <AutoBoostANonprofitModal
          partnerId={user.partnerId}
          onCloseHandler={() => {
            setModal(PromoModal.NoModal)
            setCurrentPromo(null)
          }}
          user={user}
          openToast={() => {
            setCustomSuccessMessage(
              currentPromo
                ? 'Your promo has been updated!'
                : 'Boost set up! Check your inbox for a confirmation email'
            )
            setOpenToast(true)
          }}
          setMessage={(e: CustomError | null) => {
            setCustomErrorMessage(e)
          }}
          openNonprofitModalAction={() => setModal(PromoModal.RequestANewNonprofit)}
          promo={currentPromo}
        />
      )
      break
    case PromoModal.RequestANewNonprofit:
      layout.push(
        <RequestNewNonprofitCauseModal
          partnerId={user.partnerId}
          openToast={() => setOpenToast(true)}
          setMessage={(e: CustomError | null) => {
            setCustomErrorMessage(e)
          }}
        />
      )
      break
    case PromoModal.DeleteAPromoConfirmation:
      layout.push(
        <BeamModal
          open={true}
          body={
            <div className={'pt-4 flex flex-col items-center'}>
              <div className={'pt-12 pb-6'}>
                <BeamButton
                  variant={'elevated'}
                  label={'Delete Promo'}
                  className={'w-min'}
                  onClick={async () => {
                    if (currentPromo?.id) {
                      const deletePromoResponse = await deletePromo(currentPromo.id, user.partnerId)

                      if (featureFlags['enhanced-features']) {
                        const updatedUser: TUser = {
                          ...user,
                          enhancedFeatures: {
                            permissions: {
                              ...user.enhancedFeatures?.permissions,
                              ...deletePromoResponse.enhancedFeatures.permissions,
                            },
                          },
                        }
                        dispatch(setUser(updatedUser))
                      }
                    }

                    setOpenToast(true)
                    setCustomSuccessMessage('Promo has been deleted')
                    setCurrentPromo(null)
                    setModal(PromoModal.NoModal)
                  }}
                />
              </div>
            </div>
          }
          label={'Are you sure you want to delete?'}
          onCloseCallback={() => setModal(PromoModal.NoModal)}
        />
      )
      break
    default:
      layout.push(<></>)
      break
  }

  layout.push(
    <BeamToast
      onClose={() => setOpenToast(false)}
      open={openToast}
      closable={true}
      text={
        customErrorMessage ? customErrorMessageHelper(customErrorMessage) : customSuccessMessage
      }
      variant={customErrorMessage ? 'error' : 'success'}
      icon={customErrorMessage ? null : <span>&#127881;</span>}
      duration={10000}
    />
  )

  return <>{layout}</>
}

interface DeletePromoResponse {
  id: number
  enhancedFeatures: {
    permissions: {
      campaign_action: {
        access: boolean
        countUsed: number
        countRemaining: number | 'Unlimited'
        maximum: number | 'Unlimited'
      }
    }
  }
}

export async function deletePromo(promoId: number, partnerId: number) {
  const url = `${getV2ApiUrl()}/promotions/delete`
  const res = await axiosRequest('DELETE', url, { promoId: +promoId, partnerId: +partnerId })

  return res.data as DeletePromoResponse
}
