import cx from 'classnames'
import { useContext, useState } from 'react'
import { useHistory } from 'react-router'

import { useBeamSelector } from '../../../../hooks'
import { BeamButton } from '../../../../stories/BeamButton'
import { BeamDataPointBlock } from '../../../../stories/BeamDataPointBlock'
import { BeamLoadingIndicator } from '../../../../stories/BeamLoadingIndicator'
import { BeamTooltip } from '../../../../stories/BeamTooltip'
import { dollarFormat, percentFormat } from '../../../../utils/root'
import { TUser } from '../../../../utils/types'
import { CenteredContent } from '../../../root/CenteredContent'
import { InfoIconComponent } from '../../../root/iconComponents/InfoIconComponent'
import { dataWikiUrl } from '../../../root/Root'
import { TitleWithTooltip } from '../../../root/TitleWithTooltip'
import $$ from '../overview-page.module.css'
import { overviewTestIds } from '../OverviewPage.helper'
import { OptimalRoiWithSubscriptionsResponse } from '../OverviewPage.types'
import { TimeframeToggleContext } from '../TimeframeToggleProvider'
import {
  displayDateRange,
  shouldDisplayAnyOneTimePurchaseMetrics,
  shouldDisplayAnySubscriptionMetrics,
  shouldShowCCRMetrics,
  shouldWeDisplayAOF,
  shouldWeDisplayAOV,
  shouldWeDisplaySubscriptionAOF,
  shouldWeDisplaySubscriptionAOV,
  shouldWeDisplaySubscriptionSignupLift,
} from './BusinessImpactModule.helpers'
import { OneTimePurchaseDataSection } from './OneTimePurchaseDataSection'
import { SubscriptionPurchaseDataSection } from './SubscriptionPurchaseDataSection'
import { TimeframeViewToggles } from './TimeframeViewToggles'

const howWeCalculateRoiLink =
  'https://judicious-pilot-61e.notion.site/How-we-calculate-ROI-ef8b70a6d6c44b169a4ddd5a8865a752?pvs=4'

interface BusinessImpactModuleProps {
  roiData: OptimalRoiWithSubscriptionsResponse
  loading?: boolean
}

export const BusinessImpactModuleClone = ({
  roiData,
  loading = true,
}: BusinessImpactModuleProps) => {
  const { timeframeView } = useContext(TimeframeToggleContext)
  const history = useHistory()

  const user: TUser | undefined = useBeamSelector(({ user }) => user)
  const [displayExpandedData, setDisplayExpandedData] = useState<boolean>(false)

  const oneMonthMetrics = roiData.oneMonthMetrics.optimalMetrics
  const sixMonthMetrics = roiData.sixMonthViewMetrics
  const metricsData = timeframeView === 'sixMonth' ? sixMonthMetrics : oneMonthMetrics
  const roiDateRangePeriod = displayDateRange(metricsData)
  const pctCcrDisplayValue = percentFormat(metricsData.pctCcrLift, null, 1, true)

  const showOneTimePurchaseMetrics = shouldDisplayAnyOneTimePurchaseMetrics(metricsData)
  const showSubscriptionMetrics = shouldDisplayAnySubscriptionMetrics(metricsData)

  const onlyDisplayOneTimePurchaseData = showOneTimePurchaseMetrics && !showSubscriptionMetrics
  const onlyDisplaySubscriptionPurchaseData = !showOneTimePurchaseMetrics && showSubscriptionMetrics

  function getSalesLiftTitle(): string {
    let title =
      showOneTimePurchaseMetrics && showSubscriptionMetrics
        ? 'Min. incremental revenue driven'
        : 'Sales Lift'
    if (onlyDisplayOneTimePurchaseData || onlyDisplaySubscriptionPurchaseData) {
      title = 'Min. incremental revenue driven'
    }

    if (metricsData.isEstimatedCCRIncluded) {
      title = title + ' (with est. CCR)'
    }

    return title
  }

  return (
    <div className={cx($$.businessImpactSection, { 'h-[800px] desktop:h-[450px]': loading })}>
      <div className="grid grid-cols-1 mb-2 desktop:grid-cols-2">
        <div className={'desktop:col-start-1 flex items-center'}>
          <h2 className="my-0">ROI Highlight</h2>
        </div>

        {showSubscriptionMetrics && (
          <div
            className={
              'flex col-start-1 pb-2 desktop:pb-0 desktop:col-start-2 desktop:justify-end'
            }>
            <TimeframeViewToggles />
          </div>
        )}

        <div className={'row-start-2 pb-4 desktop:pb-0 desktop:row-start-2 desktop:col-start-1'}>
          <div className={cx($$.businessImpactDateRange)}>
            <span className={'block'}>
              These estimates are conservative and represent the minimum potential ROI.
            </span>{' '}
            <a href={howWeCalculateRoiLink} target={'_blank'} rel="noopener noreferrer">
              Learn how we calculate ROI
            </a>
          </div>
        </div>

        <div
          className={
            'flex desktop:row-start-2 desktop:col-start-2 desktop:justify-end desktop:items-center'
          }>
          <div className={cx($$.businessImpactDateRange, 'text-charcoal-600')}>
            {timeframeView === 'sixMonth'
              ? `Projection calculated with data collected between ${roiDateRangePeriod}`
              : `Calculated with data collected between ${roiDateRangePeriod}`}
          </div>
        </div>
      </div>

      {loading && (
        <CenteredContent>
          <BeamLoadingIndicator />
        </CenteredContent>
      )}

      {!loading && metricsData && (
        <>
          <div
            className={cx('grid grid-cols-1 desktop:grid-cols-3', $$.businessImpactPart1)}
            data-testid={overviewTestIds.blendedRoiMetric}>
            <div data-testid={overviewTestIds.salesLiftRoiMetric}>
              <BeamDataPointBlock
                title={
                  <TitleWithTooltip
                    title={getSalesLiftTitle()}
                    tooltipContent={
                      <>
                        The conservative total incremental revenue driven by the Beam integration by
                        optimizing your on-site experience, beyond the revenue you would have
                        generated anyway
                        <br />
                        <br />
                        Note: Min. monthly incremental revenue is conservative, as it excludes the
                        revenue impact of top-of-funnel activations (e.g., increased ad efficiency,
                        friend referral, social sharing, and new customers driven from Beam’s
                        consumer app)
                      </>
                    }
                  />
                }
                description={
                  !showSubscriptionMetrics || timeframeView === 'sixMonth'
                    ? undefined
                    : 'Subscriber Signup Lift not included'
                }
                datum={metricsData.salesLift ? `~${dollarFormat(metricsData.salesLift)}` : '--'}
                datumSize="large"
              />
            </div>

            <div>
              <BeamDataPointBlock
                title={
                  <TitleWithTooltip
                    title="ROI against Donations and Beam Fees"
                    tooltipContent={
                      <>
                        Top-line sales impact / (Beam fees + total donations)
                        <br />
                        <br />
                        Note: if your brand was already donating prior to launching the Beam
                        integration, see &quot;ROI against Beam fees&quot; for ROI on just
                        incremental program costs
                      </>
                    }
                  />
                }
                datum={`~${metricsData.blendedROI?.toFixed(1)}x`}
                datumSize="large"
              />
            </div>

            <div data-testid={overviewTestIds.beamRoiMetric}>
              <BeamDataPointBlock
                title={
                  <TitleWithTooltip
                    title="ROI against Beam Fees only"
                    tooltipContent={
                      <>
                        Top-line sales lift / Beam fees
                        <br />
                        <br />
                        Note: this is the accurate representation of program ROI for brands that
                        were already donating prior to launch Beam
                      </>
                    }
                  />
                }
                datum={metricsData.beamROI ? `~${metricsData.beamROI.toFixed(1)}x` : '--'}
                datumSize="large"
              />
            </div>

            <div className="desktop:col-span-3" data-testid={overviewTestIds.roiDetailsView}>
              <button
                className={cx($$.expansionButton, 'text-right w-full underline p-5 leading-5')}
                onClick={() => setDisplayExpandedData(!displayExpandedData)}>
                {displayExpandedData ? 'Collapse' : 'See'} ROI Calculation
              </button>
              <div
                className={cx(displayExpandedData ? 'block' : 'hidden', $$.expandedStats, 'p-4')}>
                {[
                  shouldWeDisplayAOV(metricsData),
                  shouldWeDisplayAOF(metricsData),
                  shouldShowCCRMetrics(metricsData),
                  showSubscriptionMetrics,
                ].some(checkIfAnyIsTrue => checkIfAnyIsTrue) && (
                  <>
                    <div className="grid grid-cols-2 font-bold">
                      <h3>
                        {timeframeView === 'sixMonth'
                          ? 'Estimated 6 month sales impact'
                          : `Min. monthly incremental revenue driven${
                              onlyDisplaySubscriptionPurchaseData
                                ? ' (first order and recurring)'
                                : ''
                            }`}
                      </h3>
                      <h3 className="text-right">{`~${dollarFormat(metricsData.salesLift)}`}</h3>
                    </div>
                    <div>
                      {showSubscriptionMetrics && (
                        <>
                          {
                            <h4 className={'text-sky-800 text-md font-secondary pb-1.5'}>
                              Subscription Order Data
                            </h4>
                          }
                          <div className={'pl-5'}>
                            {shouldWeDisplaySubscriptionAOV(metricsData) && (
                              <div className="grid grid-cols-2 text-charcoal-500">
                                <div>Incremental lift from increased Average Order Value</div>
                                <div className="text-right">
                                  {dollarFormat(metricsData.beamSubscriptionAovSalesLift)}
                                </div>
                              </div>
                            )}
                            {shouldWeDisplaySubscriptionAOF(metricsData) && (
                              <div className="grid grid-cols-2 text-charcoal-500">
                                <div>Incremental lift from increased Average Order Frequency</div>
                                <div className="text-right">
                                  {dollarFormat(
                                    metricsData.beamSubscriptionOrderFrequencySalesLift
                                  )}
                                </div>
                              </div>
                            )}
                            {shouldWeDisplaySubscriptionSignupLift(metricsData) && (
                              <div className="grid grid-cols-2 text-charcoal-500">
                                <div className={'flex items-center'}>
                                  <span>Incremental revenue from increased subscriber rate</span>
                                  <span className={'ml-2 hidden desktop:inline'}>
                                    <BeamTooltip
                                      className={'subscriberSignupLiftTooltip'}
                                      content={
                                        <div slot={'content'}>
                                          <span className={'block mb-4'}>
                                            Calculated by (1) finding the # of customers Beam drives
                                            to convert as subscribers instead of one-time customers
                                            in the report period, and (2) multiplying that # by the
                                            incremental LTR that each subscriber has on a 6-month
                                            horizon vs. a one-time customer using your specific data
                                            to account for churn.
                                          </span>
                                          <BeamButton
                                            variant={'flat_white'}
                                            label={'Go to Calculation Page'}
                                            size={'small'}
                                            onClick={() => history.push(dataWikiUrl)}
                                            className={'w-min'}
                                          />
                                        </div>
                                      }>
                                      <InfoIconComponent fill={'--beam-color--charcoal-500'} />
                                    </BeamTooltip>
                                  </span>
                                </div>
                                <div className="text-right">
                                  {timeframeView === 'oneMonth' ? (
                                    <em>Not included in sales lift or ROI calculations</em>
                                  ) : (
                                    dollarFormat(metricsData.subscriberSignUpSalesLift)
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )}

                      {
                        <h4 className={'text-sky-800 text-md font-secondary pb-1.5 pt-4'}>
                          One Time Purchase Data
                        </h4>
                      }
                      <div className={'pl-5'}>
                        {shouldWeDisplayAOV(metricsData) && (
                          <div className="grid grid-cols-2 text-charcoal-500">
                            <div>Incremental lift from increased Average Order Value</div>
                            <div className="text-right">
                              {dollarFormat(metricsData.beamNonSubscriptionAovSalesLift)}
                            </div>
                          </div>
                        )}
                        {shouldWeDisplayAOF(metricsData) && (
                          <div className="grid grid-cols-2 text-charcoal-500">
                            <div>Incremental lift from increased Average Order Frequency</div>
                            <div className="text-right">
                              {dollarFormat(metricsData.beamNonSubscriptionOrderFrequencySalesLift)}
                            </div>
                          </div>
                        )}
                        {shouldShowCCRMetrics(metricsData) && (
                          <div className="grid grid-cols-2 text-charcoal-500">
                            {metricsData.isEstimatedCCRIncluded ? (
                              <div>
                                <em>
                                  Estimated Incremental lift from increased Cart Completion Rate (+
                                  {pctCcrDisplayValue})
                                </em>
                              </div>
                            ) : (
                              <div>Incremental lift from increased Cart Completion Rate</div>
                            )}
                            <div className="text-right">
                              {metricsData.isEstimatedCCRIncluded ? (
                                <em>{dollarFormat(metricsData.ccrSalesLift)}</em>
                              ) : (
                                dollarFormat(metricsData.ccrSalesLift)
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                <div className="grid grid-cols-2 font-bold">
                  <h3>Total monthly costs</h3>
                  <h3 className="text-right">{dollarFormat(metricsData.totalFees ?? 0)}</h3>
                </div>
                <div className="grid grid-cols-2 text-charcoal-500">
                  <div>
                    Donations{' '}
                    {!user?.partner.inKindDonations.displayInKindDonations
                      ? ''
                      : '(shipping cost of in-kind donations)'}
                  </div>
                  <div className="text-right">{dollarFormat(metricsData.donations ?? 0)}</div>
                  <div>Beam Fees</div>
                  <div className="text-right">{dollarFormat(metricsData.beamFees ?? 0)}</div>
                </div>
              </div>
            </div>
          </div>

          <SubscriptionPurchaseDataSection
            oneMonthMetrics={oneMonthMetrics}
            sixMonthMetrics={sixMonthMetrics}
            dataWikiData={roiData.dataWiki}
          />
          <OneTimePurchaseDataSection
            oneMonthMetrics={oneMonthMetrics}
            sixMonthMetrics={sixMonthMetrics}
            dataWikiMetrics={roiData.dataWiki}
          />
        </>
      )}

      {!loading && !metricsData && (
        <CenteredContent>
          <p className="font-bold">There was a problem fetching this data.</p>
        </CenteredContent>
      )}
    </div>
  )
}
