import cx from 'classnames'
import { isNil, round } from 'lodash'
import { useEffect, useState } from 'react'

import { useBeamSelector } from '../../../hooks'
import { dollarFormat } from '../../../utils/root'
import { Maybe, TSite, TUser } from '../../../utils/types'
import { UpDownIcon } from '../../root/iconComponents/UpDown'
import {
  getHasBrandSpecificConversionPercent,
  shouldDisplayAnyOneTimePurchaseMetrics,
  shouldDisplayAnySubscriptionMetrics,
} from '../OverviewPage/BusinessImpactModule/BusinessImpactModule.helpers'
import { fetchOptimalRoiWithSubscriptions } from '../OverviewPage/Overview.api'
import { OptimalRoiWithSubscriptionsResponse } from '../OverviewPage/OverviewPage.types'
import { BeamMetricInputBox, MetricInputTypes } from './BeamMetricInputBox/BeamMetricInputBox'
import { CalculationBox } from './CalculationBox'
import { CalculationTable } from './CalculationTable/CalculationTable'
import $$ from './data-wiki.module.css'
import { calculateSixMonthSalesLift } from './DataWikiPage.helpers'

const SIX_MONTH_DURATION_VALUE = 6

interface RoiResponseWithLoadingState {
  data: OptimalRoiWithSubscriptionsResponse | null
  loading: boolean
  error: string | null
}

function toPercent({
  input,
  defaultValue = 0,
  precision = 0,
}: {
  input: Maybe<number>
  defaultValue?: number
  precision?: number
}) {
  if (isNil(input)) {
    return `${round(defaultValue, precision)}%`
  }

  const pctValue = input * 100
  return `${round(pctValue, precision)}%`
}

export const DataWikiPage = () => {
  const [roiResponse, setRoiResponse] = useState<RoiResponseWithLoadingState>({
    data: null,
    loading: true,
    error: null,
  })
  const user: TUser | undefined = useBeamSelector(({ user }) => user)
  const site: TSite | undefined = useBeamSelector(({ site }) => site)
  const oneMonthMetrics = roiResponse.data?.oneMonthMetrics.optimalMetrics || null
  const dataWikiData = roiResponse.data?.dataWiki || null
  const hasBrandSpecificConversionPercent = getHasBrandSpecificConversionPercent(dataWikiData)
  const isSubscriptionOnly: boolean =
    !isNil(oneMonthMetrics) &&
    shouldDisplayAnySubscriptionMetrics(oneMonthMetrics) &&
    !shouldDisplayAnyOneTimePurchaseMetrics(oneMonthMetrics)

  useEffect(() => {
    if (!user?.partnerId) {
      return
    }

    setRoiResponse(prev => ({
      data: prev.data,
      loading: true,
      error: prev.error,
    }))
    ;(async () => {
      try {
        const response = await fetchOptimalRoiWithSubscriptions(
          user?.partnerId,
          site?.storeId || undefined
        )
        setRoiResponse({
          data: response,
          loading: false,
          error: null,
        })
      } catch (error: any) {
        console.error(error)
        setRoiResponse({
          data: null,
          loading: false,
          error: 'There was an error fetching your ROI data.',
        })
      }
    })()
  }, [site?.storeId, user?.partnerId])

  return (
    <article className={cx($$.dataWikiPageContainer, 'pt-14')}>
      <div className={cx($$.paragraphBlock, 'mb-11')}>
        <h1 className={'m-0 mb-2'}>Subscription Data Dictionary</h1>
        <p>
          Welcome to Beam’s Data Dictionary! Our approach to attribution is significantly more
          rigorous than that of most platforms, and this page is designed to give you full
          transparency–and even influence–over how incremental revenue is calculated in a way that
          is tailored to {user?.partner?.name}’s business dynamics, A/B test insights with Beam, and
          historic LTV values. Today the Data Dictionary outlines how we’re quantifying the number
          of additional subscribers converted by Beam and the resulting incremental revenue driven
          on a 6-month horizon- stay tuned for additional metrics here for your one-time purchases,
          too.
        </p>
      </div>
      <h2>Subscriber Signup Lift</h2>
      <div className={cx($$.paragraphBlockGroup)}>
        <div className={$$.paragraphBlock}>
          <h3>What is Subscriber Sales Lift? </h3>
          <p>
            Subscriber signup lift is the increase in Subscriber signup rate driven by Beam.
            Subscriber signup rate is the rate at which your customers who reach cart ultimately
            convert with one more subscription items in their purchase.
          </p>
        </div>

        <div className={$$.paragraphBlock}>
          <h3>How do we calculate it? </h3>
          <p>
            Beam’s biggest addressable opportunity to increase subscription signup rate is in cart,
            by increasing the rate at which customers opt into an in-cart “Subscribe & Save CTA” as
            customers feel less hesitant to do so when they can visualize their impact growing with
            each renewal. We therefore size the difference in the percentage of customers who reach
            cart that convert as subscribers across two groups: (a) those who use Beam by selecting
            a nonprofit in cart, and (b) those who do not.
          </p>
          <p>
            However, given that customers who select a nonprofit in cart are inherently more engaged
            than those who do not, it would not be intellectually honest to stop here and claim that
            Beam is driving the full delta between these groups. We therefore apply what we call an
            “A/B test discount factor” in order to remove this selection bias between these groups:
            in our extensive A/B testing, we’ve found that on average, we need to discount the
            recorded delta between Beam and Non-Beam groups by 85% to get to a true subscription
            signup lift. If we’ve run an A/B test with your brand, the discount factor used for your
            own SSL calculation can also be tailored to a discount factor determined by your own A/B
            test results–please reach out to your CSL if you’re interested in scheduling an A/B test
            to determine your own A/B test discount factor.
          </p>
        </div>
      </div>
      <div className={'mt-7'}>
        <BeamMetricInputBox
          loading={roiResponse.loading}
          data={[
            { type: MetricInputTypes.header, label: 'From Your Monthly ROI Highlight' },
            {
              type: MetricInputTypes.metric,
              label: 'Beam Subscription Signup Rate',
              value: toPercent({ input: dataWikiData?.beamFirstSubscriptionCcr, precision: 2 }),
              tooltip: `[Orders with an in-cart nonprofit selection that are tagged as First time subscription orders] / [Total carts created in this time period that had a recorded nonprofit selection, incl those that were ultimately abandoned]`,
              icon: <UpDownIcon />,
            },
            {
              type: MetricInputTypes.metric,
              label: 'Non-Beam Subscription Signup Rate',
              value: toPercent({ input: dataWikiData?.nonBeamFirstSubscriptionCcr, precision: 2 }),
              tooltip: `[Orders with no nonprofit selection that are tagged as First time subscription orders] / [Total carts created in this time period with no nonprofit selected, incl those that were ultimately abandoned]`,
              icon: <UpDownIcon />,
            },
            {
              type: MetricInputTypes.header,
              label: hasBrandSpecificConversionPercent
                ? `From ${user?.partner.name}`
                : 'Industry Benchmark',
            },
            {
              type: MetricInputTypes.metric,
              label: 'AB Test Discount Factor',
              value: hasBrandSpecificConversionPercent
                ? toPercent({ input: dataWikiData?.abTestDiscountFactor })
                : toPercent({ input: dataWikiData?.estimatedGlobalAbTestDiscountFactor }),
              tooltip: `In order to account for the fact that customers using Beam are inherently more engaged, we discount the difference in subscription signup rate among Beam vs. non-Beam customers substantially by an A/B test adjustment factor. Calculated by analyzing the many subscription signup A/B tests we’ve run, the Industry Standard A/B Test Discount Factor is found by taking the average of the % by which we had to discount the outcome observed by comparing Beam & non-Beam Subscription Signup Rates for a give brand in order to reach the outcome proven by each respective A/B test.`,
            },
          ]}
        />
      </div>
      <div className={'mt-7 pb-4'}>
        <CalculationBox
          loading={roiResponse.loading}
          operands={[
            {
              value:
                '( ' +
                toPercent({ input: oneMonthMetrics?.beamFirstSubscriptionCcr, precision: 2 }),
              description: 'Beam Subscription Signup Rate',
            },
            {
              value:
                toPercent({ input: oneMonthMetrics?.nonBeamFirstSubscriptionCcr, precision: 2 }) +
                ' )',
              description: 'Non-Beam Subscription Signup Rate',
            },
            {
              value: toPercent({
                input: oneMonthMetrics?.nonBeamFirstSubscriptionCcr,
                precision: 2,
              }),
              description: 'Non-Beam Subscription Signup Rate',
            },
            {
              value:
                '( 1 - ' +
                (hasBrandSpecificConversionPercent
                  ? toPercent({ input: dataWikiData?.abTestDiscountFactor })
                  : toPercent({ input: dataWikiData?.estimatedGlobalAbTestDiscountFactor })) +
                ' )',
              description: 'A/B Test Discount Factor',
            },
            {
              value: toPercent({
                input: oneMonthMetrics?.subscriptionSignUpLift || 0,
                precision: 2,
              }),
              description: 'Subscriber \n' + 'Signup Lift',
            },
          ]}
          operators={['-', '/', 'X', '=']}
        />
      </div>
      <h2>Incremental Subscribers Driven by Beam</h2>
      <div className={$$.paragraphBlockGroup}>
        <div className={$$.paragraphBlock}>
          <h3>What is Incremental Subscribers Driven by Beam?</h3>
          <p>
            Incremental Subscribers Driven by Beam is the # of new subscribers that Beam is driving
            in the reporting period, applying the Subscriber Signup lift proven above. A “new
            subscriber” is a customer that has become a first-time subscriber to at least 1 new
            product and without Beam would have converted as a one-time customer, as evidenced by
            the increase in subscription signup lift Beam is driving. Incremental Subscribers Driven
            by Beam therefore excludes any subscription reactivations & is agnostic of whether a
            customer had any pre-existing subscriptions to other products (i.e., a customer is
            considered a new subscriber within the reporting period if they place a “First
            Subscription” order to at least one new product).
          </p>
        </div>

        <div className={$$.paragraphBlock}>
          <h3>
            How do we calculate this? First we calculate the number of subscriptions you would have
            seen anyway without Beam.
          </h3>
          <p>
            Baseline Subscribers is the number of “First Subscription” orders you would have
            converted in the reporting period without Beam present. We calculate this by taking the
            total number of “First Subscription” orders during the reporting period and using
            subscriber signup lift to exclude the “First Subscription” orders that would have been
            incrementally driven by Beam to find your Baseline Subscribers.
          </p>
        </div>
        <div className={$$.paragraphBlock}>
          <h3>
            How do we use Baseline Subscribers to find Incremental Subscribers Driven by Beam?
          </h3>
          <p>
            Out of the total “First Subscription” orders in the period of the ROI report, we
            subtract the number of Baseline subscribers you would have converted anyway to isolate
            the purely incremental subscribers that were converted due to Beam.
          </p>
        </div>
      </div>
      <div className={'mt-7'}>
        <BeamMetricInputBox
          loading={roiResponse.loading}
          data={[
            { type: MetricInputTypes.header, label: 'From Your Monthly ROI Highlight' },
            {
              type: MetricInputTypes.description,
              text: 'Here are the provided metrics we use to calculate Incremental subscriptions driven by Beam',
            },
            {
              type: MetricInputTypes.metric,
              label: 'Total First Subscriptions',
              value: (oneMonthMetrics?.totalSubscriptionFirstOrders || 0).toLocaleString(),
              tooltip: `The total number of orders tagged as “First Subscription” orders in your OMS during the reporting period`,
              icon: <UpDownIcon />,
            },
            {
              type: MetricInputTypes.metric,
              label: 'Subscriber Signup Lift',
              value: toPercent({
                input: oneMonthMetrics?.subscriptionSignUpLift || 0,
                precision: 2,
              }),
              tooltip: `The lift in subscriber signup rate Beam is driving, discounted significantly to account to conservatively account for selection bias (see summary of methodology above)`,
              icon: <UpDownIcon />,
            },
          ]}
        />
      </div>

      <div className={'mt-5'}>
        <CalculationBox
          title={'Calculation for Baseline Subscribers'}
          loading={roiResponse.loading}
          operands={[
            {
              value: oneMonthMetrics?.totalSubscriptionFirstOrders || 0,
              description: 'Total First Subscriptions',
            },
            {
              value: '( 1',
              description: '',
            },
            {
              value:
                toPercent({ input: oneMonthMetrics?.subscriptionSignUpLift || 0, precision: 2 }) +
                ' )',
              description: 'Subscriber Signup Lift',
            },
            {
              value: round(Number(oneMonthMetrics?.baselineFirstSubscriptions)) || 0,
              description: 'Baseline Subscribers',
            },
          ]}
          operators={['/', '+', '=']}
        />
      </div>

      <div className={'pt-4 pb-5'}>
        <CalculationBox
          title={'Calculation for Incremental Subscribers Driven by Beam'}
          loading={roiResponse.loading}
          operands={[
            {
              value: round(Number(oneMonthMetrics?.totalSubscriptionFirstOrders)) || 0,
              description: 'Total First Subscriptions',
            },
            {
              value: round(Number(oneMonthMetrics?.baselineFirstSubscriptions)) || 0,
              description: 'Baseline Subscribers',
            },
            {
              value:
                round(Number(oneMonthMetrics?.incrementalFirstSubscriptionsAttributedToBeam)) || 0,
              description: 'Incremental First Subscriptions Driven by Beam',
            },
          ]}
          operators={['-', '=']}
        />
      </div>

      <h2>6 Month Sales Lift from Subscriber Signup Lift </h2>
      <div className={$$.paragraphBlock}>
        <h3>
          Now that we’ve identified how many new subscribers Beam is driving per month, how do we
          size their revenue impact?
        </h3>
        <p>
          We start by using your data to determine how much more each customer that converts as a
          subscriber instead of a one-time customer is worth on a 6-month horizon. We do this by
          inputting your own brand-specific values re: average 6-month LTV for a subscriber vs. for
          a one-time customer, often provided directly by your team or your subscription analytics
          dashboard. These inputs already account for churn, and by subtracting one time purchase
          LTV from subscriber LTV, we calculate the Incremental LTV of converting a customer to a
          subscription.
        </p>
        <p>
          We then provide a view of how this would layer out over multiple cohorts of new
          subscribers acquired between Month 1 and Month 6. In order to do so, we use the rough
          assumption that the gains in LTR that you capture by converting a customer as a subscriber
          instead of as a one-time customer are evenly distributed over each of the 6 months, and we
          adjust the total incremental revenue we expect to drive for each cohort by the portion of
          the 6-month period that each cohort has been active.
        </p>
      </div>
      <div className={'pb-4'}>
        <div className={'mt-7'}>
          <BeamMetricInputBox
            loading={roiResponse.loading}
            data={[
              { type: MetricInputTypes.header, label: 'From Your Monthly ROI Highlight' },
              {
                type: MetricInputTypes.metric,
                label: 'Incremental First Subscriptions Driven by Beam',
                value: (
                  round(Number(oneMonthMetrics?.incrementalFirstSubscriptionsAttributedToBeam)) || 0
                ).toLocaleString(),
                tooltip: `The number of additional subscribers Beam converted, calculated by multiplying Total Carts by ${user?.partner.name}'s Subscriber Signup Lift`,
                icon: <UpDownIcon />,
              },
              { type: MetricInputTypes.header, label: `${user?.partner.name}'s Data` },
              {
                type: MetricInputTypes.metric,
                label: '6 month subscriber LTV',
                value: dollarFormat(dataWikiData?.sixMonthSubscriptionLtv || null),
                tooltip: `Lifetime customer value of ${user?.partner.name}'s subscribers over a 6 month period`,
              },
              ...(isNil(dataWikiData?.sixMonthNonSubscriptionLtv)
                ? []
                : [
                    {
                      type: MetricInputTypes.metric,
                      label: '6 month one time purchase LTV',
                      value: dollarFormat(dataWikiData?.sixMonthNonSubscriptionLtv || null),
                      tooltip: `Lifetime customer value of ${user?.partner.name}'s customers making one-time purchases over a 6 month period`,
                    },
                  ]),
              {
                type: MetricInputTypes.description,
                label: 'Footer',
                value:
                  'Note: This calculation assumes that Beam is not lifting the LTR of your current subscribers. From A/B testing, we know that Beam is driving consistent LTR lift which makes this analysis more conservative- reporting on the LTR lift Beam is driving is coming soon.',
              },
            ]}
          />
        </div>
      </div>
      <div className={cx($$.paragraphBlock)}>
        <p>
          <em>
            Note: This calculation assumes that Beam is not lifting the LTR of your current
            subscribers. From A/B testing, we know that Beam is driving consistent LTR lift which
            makes this analysis more conservative- reporting on the LTR lift Beam is driving is
            coming soon.
          </em>
        </p>
      </div>
      <div className={$$.paragraphBlock}>
        <h3>
          How do we quantify the revenue impact for a single cohort of Incremental Subscribers
          driven by Beam?
        </h3>
        <p>
          For the incremental first subscriptions driven by Beam in a given cohort, we estimate how
          much incremental LTV the brand has captured for those customers in the time they have been
          active.
        </p>
      </div>
      <div className={'py-5'}>
        <CalculationBox
          loading={roiResponse.loading}
          title={'Calculation: Single Cohort'}
          operands={[
            {
              value:
                round(Number(oneMonthMetrics?.incrementalFirstSubscriptionsAttributedToBeam)) || 0,
              description: 'Incremental First Subscriptions Driven by Beam',
            },
            { value: SIX_MONTH_DURATION_VALUE, description: 'Months this Cohort is Active' },
            {
              value: SIX_MONTH_DURATION_VALUE,
              description: (
                <>
                  <span className={'whitespace-nowrap'}>6-Month</span> Period
                </>
              ),
            },
            {
              value: dollarFormat(dataWikiData?.incrementalLtv, 0),
              description: isSubscriptionOnly
                ? '6 Month LTV per subscriber'
                : 'Incremental LTV per subscriber vs one time customer LTV',
            },
            {
              value: dollarFormat(
                calculateSixMonthSalesLift({
                  incrementalFirstSubscriptionsAttributedToBeam:
                    dataWikiData?.incrementalFirstSubscriptionsAttributedToBeam || 0,
                  monthsActive: SIX_MONTH_DURATION_VALUE,
                  maxMonthCount: SIX_MONTH_DURATION_VALUE,
                  incrementalLtv: dataWikiData?.incrementalLtv || 0,
                })
              ),
              description: '6 Month Sales Lift for One Monthly Cohort',
            },
          ]}
          operators={['X', '/', 'X', '=']}
        />
        <div className={cx($$.paragraphBlock)}>
          <p>
            <em>
              Assumption: The value of the subscription will evenly accrue throughout the 6 month
              period (1/6 of the 6 month value for each of the first 6 months they are live)
            </em>
          </p>
        </div>
      </div>
      <div className={$$.paragraphBlock}>
        <h3>
          How do we measure the total 6-month sales lift, accounting for multiple cohorts of
          Incremental Subscribers driven by Beam on a 6-month horizon?
        </h3>
        <p>
          We apply the single cohort sales lift calculation to each of the cohorts from the 6-month
          period. Adding the lift from each individual cohort, we calculate the full 6-month sales
          lift your brand has realized. We have not assumed any LTR gains from Beam during this
          period, which makes this analysis even more conservative (additional visualization of LTR
          impact coming soon).
        </p>
      </div>

      <div className={'pt-5'}>
        <CalculationTable
          title={'Calculation: 6 Month Sales Lift Impact'}
          data={roiResponse.data}
          loading={roiResponse.loading}
        />
      </div>
    </article>
  )
}
