import { createColumnHelper } from '@tanstack/react-table'
import cx from 'classnames'
import { ReactNode } from 'react'

import { BeamCard } from '../../../../stories/BeamCard'
import { BeamLoadingIndicator } from '../../../../stories/BeamLoadingIndicator'
import { BeamTable } from '../../../../stories/BeamTable'
import { dollarFormat } from '../../../../utils/root'
import { displayDateRange } from '../../OverviewPage/BusinessImpactModule/BusinessImpactModule.helpers'
import { OptimalRoiWithSubscriptionsResponse } from '../../OverviewPage/OverviewPage.types'
import { calculateSixMonthSalesLift } from '../DataWikiPage.helpers'
import $$ from './calculation-table.module.css'

export interface CalculationTableProps {
  title: string
  data: OptimalRoiWithSubscriptionsResponse | null
  loading?: boolean
}
export interface CalculationTableColumns {
  signupMonth: string
  incrementalSubscribers: number
  monthsActive: number
  estimatedLiftPerSubscriber: number
  salesLift: number
}

export const CalculationTable = ({ title, data, loading = true }: CalculationTableProps) => {
  const roiDateRangePeriod = displayDateRange(data?.oneMonthMetrics.optimalMetrics || null)
  const maxMonthCount = 6

  const tableInfo: CalculationTableColumns[] = !data?.oneMonthMetrics
    ? []
    : Array.from({ length: maxMonthCount }).map((_, index) => {
        const signupMonthNumber = index + 1
        const incrementalSubscribersData =
          data?.oneMonthMetrics.optimalMetrics.incrementalFirstSubscriptionsAttributedToBeam
        const incrementalSubscribers = incrementalSubscribersData
          ? parseFloat(incrementalSubscribersData.toFixed(0))
          : 0
        const monthsActive = maxMonthCount - index
        const estimatedLiftPerSubscriber = data?.dataWiki.incrementalLtv || 0
        const salesLiftValue = calculateSixMonthSalesLift({
          incrementalFirstSubscriptionsAttributedToBeam: incrementalSubscribers,
          monthsActive,
          maxMonthCount,
          incrementalLtv: estimatedLiftPerSubscriber,
        })
        return {
          signupMonth: `Month ${signupMonthNumber}`,
          incrementalSubscribers,
          monthsActive,
          estimatedLiftPerSubscriber,
          salesLift: salesLiftValue,
        }
      })

  const HeaderCellWrapper = ({ children }: { children: ReactNode }) => (
    <div className={$$.headerLabel}>{children}</div>
  )
  const columnHelper = createColumnHelper<CalculationTableColumns>()
  const columns = [
    columnHelper.accessor('signupMonth', {
      header: 'SIGNED UP',
      cell: info => info.getValue(),
      footer: () => <span className={'text-sky-800'}>6 Month Impact Sales Lift</span>,
    }),
    columnHelper.accessor('incrementalSubscribers', {
      header: () => <HeaderCellWrapper>Incremental Subscribers</HeaderCellWrapper>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('monthsActive', {
      header: () => <HeaderCellWrapper>Months Active</HeaderCellWrapper>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('estimatedLiftPerSubscriber', {
      header: () => <HeaderCellWrapper>Estimated Lift Per Subscriber</HeaderCellWrapper>,
      cell: info => dollarFormat(Number(info.renderValue()) || null),
    }),
    columnHelper.accessor('salesLift', {
      header: () => <HeaderCellWrapper>Sales Lift</HeaderCellWrapper>,
      cell: info => dollarFormat(Number(info.renderValue()) || null),
      footer: () => (
        <span className={'text-sky-800'}>
          {dollarFormat(data?.sixMonthViewMetrics.subscriberSignUpSalesLift)}
        </span>
      ),
    }),
  ]

  return (
    <BeamCard backgroundColor={'--beam-color--lime-50'} excludeBorder>
      <h3 className={'m-0'}>{title}</h3>
      {loading ? (
        <div className={cx('flex items-center justify-center h-[164px]')}>
          <BeamLoadingIndicator />
        </div>
      ) : (
        <>
          <p className={'p-0 pb-2.5 text-md'}>
            Calculated using data from report period {roiDateRangePeriod}
          </p>
          <BeamTable
            className={$$.calculationTable}
            columns={columns}
            data={tableInfo}
            enableSorting={false}
            noDataMessage={'No data available'}
          />
        </>
      )}
    </BeamCard>
  )
}
